import { request } from '@/libs'

/**
 * 注册相关
 */

// 获取手机验证码
export function getVerificationCode(params) {
  return request.get("/huiyue-cloud-authserver/register/verif", { params })
}

// 发送手机验证码
export function sendMsg(params) {
  return request.get("/huiyue-cloud-authserver/register/sendmsg", { params })
}

// 注册账号
export function register(params) {
  return request.post("/huiyue-cloud-authserver/register/register", params)
}

// 删除账号
export function deleteUser(params) {
  // return request.delete(`/huiyue-cloud-authserver/user/delete`, { params })
  return request({
    url: `/huiyue-cloud-authserver/user/delete`,
    method: 'delete',
    data: params,
    params: params
})
}