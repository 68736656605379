<template>
  <div class="register-warp">
    <div class="register-content">
      <div class="title f-28">欢迎注册</div>
      <div class="subtitle">已有账号?  去<span class="login c-h" @click="$router.push('/login')">登录</span></div>
      <Form ref="form" :model="formItem" :rules="rule" class="register-form" :label-width="100" label-position="left" hide-required-mark>
        <FormItem label="手 机 号" prop="phone" class="m-b-30">
          <Input v-model="formItem.phone" placeholder="请输入手机号码" clearable />
        </FormItem>
        <FormItem label="昵 称" prop="NickName" class="m-b-30">
          <Input v-model="formItem.NickName" placeholder="请输入昵称" clearable />
        </FormItem>
        <FormItem label="密 码" prop="password" class="m-b-30">
          <Row type="flex" justify="center" align="middle">
            <Col span="22">
            <Input v-model="formItem.password" placeholder="请输入密码" :type="isPassword ? 'password' : 'text'" autocomplete="new-password" clearable />
            </Col>
            <Col span="2" class="text-r">
              <Icon size="20" type="md-eye" class="c-h h-blue m-t-5" @click="isPassword = !isPassword" v-if="isPassword" />
              <Icon size="20" type="md-eye-off" class="c-h h-blue m-t-5" @click="isPassword = !isPassword" v-else />
            </Col>
          </Row>
        </FormItem>
        <FormItem label="验 证 码" prop="verif" class="m-b-30">
          <Row :gutter="10">
            <Col span="16">
            <Input v-model="formItem.verif" placeholder="请输入验证码"  clearable />
            </Col>
            <Col span="8">
              <img :src="codeUrl" class="img-code m-t-1 c-h" @click="getCode()" />
            </Col>
          </Row>
        </FormItem>
        <FormItem label="手 机 验 证 码" prop="msg" class="m-b-30">
          <Row :gutter="10">
            <Col span="16">
            <Input v-model="formItem.msg" placeholder="请输入手机验证码"  clearable />
            </Col>
            <Col span="8">
              <Button @click="getMsg" :disabled="auth.disabled">{{ auth.text }}</Button>
            </Col>
          </Row>
        </FormItem>
        <div class="form-footer">
          <Button type="info" shape="circle" long @click="registerUser">注册并登录</Button>
        </div>
      </Form>
      <div class="logo"></div>
    </div>
  </div>
</template>

<script>
import { config } from "@/config"
import { getVerificationCode, sendMsg, register, deleteUser } from "@/libs/api/register"
import { util } from "@/libs"
export default {
  data() {
    return {
      isPassword: true,
      codeUrl: "",
      nowTime: null,
      timer: null,
      auth: {
        status: false,
        mobile: '',
        code: null,
        count: 60,
        text: '发送验证码',
        disabled: false
      },
      formItem: {},
      rule: {
        phone: { required: true, pattern: config.pattern.mobile, message: "请输入正确的手机号码" },
        NickName: { required: true, message: "请输入昵称" },
        password: { required: true, pattern: config.pattern.pass, message: "密码长度6-22位" },
        verif: { required: true, message: "请输入验证码" },
        msg: { required: true, message: "请输入获取到的手机验证码" },
      }
    }
  },
  methods: {

    // 获取图形验证码
    getCode() {
      this.nowTime = new Date().getTime()
      if(this.formItem.verif) {
        this.formItem.verif = ""
      }
      getVerificationCode({ r: this.nowTime }).then(res => {
        this.codeUrl = res
      })
    },

    // 获取短信验证码
    getMsg() {
      if(!this.formItem.phone) {
        this.$Message.warning("请先输入手机号码！")
        return
      }
      if(!this.formItem.verif) {
        this.$Message.warning("请先输入验证码！")
        return
      }

      sendMsg({
        r: this.nowTime,
        verif: this.formItem.verif,
        phone: this.formItem.phone,
        apiDesc: "1"
      }).then(res => {
        const timer = setInterval(() => {
          if (this.auth.count == 0) {
            clearInterval(timer)
            Object.assign(this.auth, { count: 60, text: '重新发送', disabled: false })
          } else {
            Object.assign(this.auth, { text: `重新发送(${this.auth.count--})`, disabled: true })
          }
        }, 1000, 60)
      })
    },

    loginSuccess(res) {
      const { routers, user, authCode } = this.$store.state.user
      this.$store.dispatch('user/getAuthCode', { clientId: '1001', redirectUri: 'http://pan.xingyaedu.cn' }).then(res => {
        if(res.code == 200) {
          this.$store.dispatch('user/getTokenNetdisk', { authCode: res.data })
        }
      })
      // TODO: 同时获取token，研学的token获取不到
      // this.$store.dispatch('user/getAuthCode', { clientId: '1001', redirectUri: 'http://web.xingyaedu.cn' }).then(res => {
      //     if(res.code == 200) {
      //         this.$store.dispatch('user/getTokenMarket', { authCode: res.data })
      //     }
      // })
      this.$store.dispatch('user/getAuthCode', { clientId: '1004', redirectUri: 'http://yanxue.manage.xingyaedu.cn' }).then(res => {
        if(res.code == 200) {
          this.$store.dispatch('user/getTokenResearch', { authCode: res.data }).then((res) => {
            this.$router.addRoutes(routers)
            const [dataV, system, config] = routers
            const path = dataV.index || config.index || system.index
            if(path) {
                this.$router.push(path)
            }
          })
        }
      })
        

    },

    // 注册账号
    registerUser() {
      this.$refs.form.validate(valid => {
        if(valid) {
          register(util.toFormData({
            phone: this.formItem.phone,
            msg: this.formItem.msg,
            password: this.formItem.password,
            NickName: this.formItem.NickName
          })).then(res => {
            if(res.code == 200) {
              this.$store.commit('user/USER_LOGIN', res.data)
              this.loginSuccess()
            } else {
              this.$Message.error(res.msg)
            }
          })
        }
      })
    },

  },
  mounted() {
    this.getCode()
  }
}
</script>

<style lang="less" scoped>
.register-warp {
  width: 100%;
  height: 100%;
  position: fixed;
  background: url("/static/images/login/login_bg.jpg") no-repeat;
  background-size: cover;
  .register-content {
    width: 500px;
    height: 600px;
    background: #fff;
    border-radius: 10px;
    position: absolute;
    top: calc(50% - 300px);
    left: calc(50% - 10px);
    padding: 40px;
    .logo {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      background: url('/static/images/login/logo.png') no-repeat center center;
      background-size: contain;
      position: absolute;
      left: calc(50% + 10px);
      top: -50px;
    }
    .title {
      height: 60px;
      line-height: 60px;
      // font-family: "黑体";
      font-weight: bolder;

    }
    .login {
      color: #6464f1;
      &:hover {
        color: #0000ff;
      }
    }
    .h-blue:hover {
      color: #3838ee;
    }
    .register-form {
      margin-top: 40px;
    }
    .form-footer {
      margin-top: 40px;
    }
    .img-code {
      width: 100px;
      height: 30px;
      // object-fit: cover;
      // image-rendering: pixelated;
      // image-rendering: -moz-crisp-edges; /* Firefox */     
      // image-rendering: -o-crisp-edges; /* Opera */      
      // image-rendering: -webkit-optimize-contrast; /*Webkit (non-standard naming) */
      // image-rendering: crisp-edges; 
      // -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
    }
  }
}
</style>